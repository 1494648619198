import React, {useEffect} from "react"
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../layouts/layout";
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "react-bootstrap";
import VideoPlaceholder from "../images/video-placeholder.jpg";

const Subscribe = () => {
    const data = useStaticQuery(graphql`
        query SubscribeQuery {
            videoData: contentfulLayout(contentful_id: {eq: "T3H8XFGXUuivr4KGfj91v"}) {
                hero {
                    header_title
                    hero_body {
                      hero_body
                    }
                    heroImage {
                      url
                    }
                }
            }
        }
    `)

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    portalId: '4293115',
                    formId: 'd61f67ce-76c7-46f4-91fc-643574b6d2aa',
                    target: '#hubspot-form'
                })
            }
        });
    }, []);

    const videoPage = data.videoData
    const heroImage = videoPage.hero.heroImage.url

    return(
        <Layout>
            <Helmet>
                <title>Subscribe | O3 Edge | Retirement Plan Prospecting</title>
                <meta name="description" content="Want to see more? Subscribe to learn how the O3 Edge suite can help you target the right opportunities and grow your practice." />
            </Helmet>

            <div className="section-container position-relative">
                <Container className="form-container">
                    <Row className="header-row">
                        <Col className="text-center">
                            <h1>Subscribe to our newsletter</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" className="offset-lg-3">
                            <div id="hubspot-form"></div>
                        </Col>
                    </Row>
                </Container>
                <div className="background-container">
                    <video autoPlay muted loop className="video" height="100%" poster={VideoPlaceholder}>
                        <source src={heroImage} type="video/mp4" />
                    </video>
                </div>
            </div>
        </Layout>
    )
}

export default Subscribe